
import { Component, Prop, Vue } from "vue-property-decorator";
import { State, Getter, Action, Mutation, namespace } from "vuex-class";
import dayjs from "dayjs";
import CouponContent from "@/views/marketing/components/coupon-discount-content.vue";
import t from "@common/src/i18n/t";
const base = namespace("base");
const user = namespace("user");
const coupon = namespace("coupon");
@Component({
  components: {
    CouponContent
  }
})
export default class LookCoupon extends Vue {
  @base.Action getDictionaryList;
  @coupon.Action getDetail;
  @user.State userData;
  @user.State selectedOrgCode;
  //计算属性
  get filterUserData() {
    return this.userData.storeList.filter(item => {
      return item.orgCode === this.selectedOrgCode;
    });
  }
  dayjs = dayjs;
  get breadData() {
    return [
      { name: t("marketing.discount-coupon"), path: "/marketing/discount-coupon" },
      { name: t("v210831.view-coupons") }
    ];
  }
  disInput = "1";
  activityStatus = null;
  couponForm = {
    activityName: "",
    time: null, //[new Date(2000, 10, 10, 10, 10), new Date(2000, 10, 11, 10, 10)]
    number: null,
    remark: null,
    totalQuantity: "",
    activityDesc: "",
    type: "",
    radio: "1",
    radioNumber: null,
    radioNumberYuan: undefined,
    activityBegin: undefined,
    activityEnd: undefined,
    doorsill: undefined,
    multipleValue: [],
    multipleProject: [],
    couponType: undefined,
    rewardType: undefined,
    rewardValue: "",
    productDetails: [],
    limitAmount: undefined
  };
  created() {}
  mounted() {
    this.init();
  }
  async init() {
    let res = await this.getDetail({
      activityCode: this.$route.params.activityCode
    });
    // console.log("[ res ]", res);
    this.activityStatus = res.data.activityStatus;
    this.couponForm.activityName = res.data.activityName;
    this.couponForm.activityBegin = res.data.activityBegin;
    this.couponForm.activityEnd = res.data.activityEnd;
    this.couponForm.totalQuantity = res.data.totalQuantity;
    this.couponForm.activityDesc = res.data.activityDesc;
    this.couponForm.couponType = res.data.couponType;
    this.couponForm.rewardType = res.data.rewardType;
    this.couponForm.rewardValue = res.data.rewardValue;
    this.disInput = res.data.couponTypeDesc;
    this.couponForm.productDetails = res.data.productDetails;
    this.couponForm.limitAmount = res.data.limitAmount;
  }
  editCoupon() {
    this.$router.push(`/marketing/created-coupon/${this.$route.params.activityCode}`);
  }
  routerPush() {
    this.$router.push(`/marketing/send-coupon/${this.$route.params.activityCode}`);
  }
}
